<template>
	<v-chip v-bind="settings">
		{{ mwutils.prettyPrint(value, "TITLE") }}
	</v-chip>
</template>

<script>
export default {
	name: "StringCell",
	props: ["item", "column"],
	computed: {
		value(){
			return this.item[this.column.value]
		},
		colors(){
			return this.$store.state.status_colors
		},
		settings(){
			let color = this.colors[this.value] || "secondary";
			return {
				color
			}
		}
	}
};
</script>
